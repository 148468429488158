export class apiList {
  login: string = '/Admin/login';
  forgotPassword: string = '/Admin/sendNewPasswordInEmail';
  changePassword: string = '/Admin/changePassword';
  updateProfile: string = '/Admin/updateProfile';
  profile: string = '/Admin/getProfile';
  fileUpload: string = '/Admin/uploadFile';

  //user profile
  getUser: string = '/Admin/getUser';
  getUserById: string = '/Admin/getUserById';
  updateUser: string = '/Admin/updateUser';
  getUserCsv: string = '/Admin/getUserCsv';
  deleteUser: string = '/Admin/deleteUser';

  //get Medical History
  getHealth: string = '/Admin/getHealth';
  getUserHealth: string = '/Admin/getUserHealth';
  getUserHealthById: string = '/Admin/viewUserHealth';

  //category
  getCategory: string = '/Admin/getCategory';
  postCategory: string = '/Admin/addCategory';
  editCategory: string = '/Admin/editCategory';
  getCategoryById: string = '/Admin/viewCategory';
  deleteCategory: string = '/Admin/deleteCategory';

  //services
  getService: string = '/Admin/getService';
  deleteService: string = '/Admin/deleteService';
  addService: string = '/Admin/addService';
  editService: string = '/Admin/editService';

  //clinic
  getClinic: string = '/Admin/getClinic';
  addClinic: string = '/Admin/addClinic';
  viewClinic: string = '/Admin/viewClinic';
  editClinic: string = '/Admin/editClinic';
  deleteClinic: string = '/Admin/deleteClinic';

  //notification
  addNotification: string = '/Admin/addNotification';
  getNotification: string = '/Admin/getNotification';
  getNotificationById: string = '/Admin/viewNotification';
  editNotification: string = '/Admin/editNotification';
  deleteNotification: string = '/Admin/deleteNotification';

  //cms
  getCms: string = '/Admin/getCms';
  postCms:string = '/Admin/addCms';
  deleteCms:string = '/Admin/deleteCms';
  //manageContent
  getContent: string = '/Admin/getContent';
  getContentById: string = '/Admin/viewContent';
  addContent: string = '/Admin/addContent';
  editContent:string='/Admin/editContent';
  deleteContent:string='/Admin/deleteContent';
  //Manage Care Provider
  getVendor:string = '/Admin/getVendor';
  updateVendorById:string = '/Admin/updateVendor';
  getVendorById:string = '/Admin/getVendorById';
  addVendor:string = '/Admin/addVendor';
  deleteVendor: string = '/Admin/deleteVendor';
  updateVendorStatus: string = '/Admin/updateVendorStatus';

  //Review
  ratings:string = '/Admin/ratings'

  //Booking
  getOrder:string = '/Admin/getOrder'
  updateBookingStatus:string = '/Admin/updateBookingStatus'
  getOrderById:string = '/Admin/getOrderById'
  updateOrder:string = '/Admin/updateOrder'


  //Dashboard
  dashboard:string = '/Admin/dashboard'
  dashboardData:string = '/Admin/dashboardData'

  //Tools
  addTools:string = '/Admin/addTools'
  getTools:string = '/Admin/getTools'
  editTools:string = '/Admin/editTools'
  deleteTools:string = '/Admin/deleteTools'
  viewTools:string = '/Admin/viewTools'

  //Tools Question
  addQuestion:string = '/Admin/addQuestion'
  getQuestion:string = '/Admin/getQuestion'
  editQuestion:string = '/Admin/editQuestion'

  //tools Users
  getUserToolResponse:string = '/Admin/getUserToolResponse'
  getUserAnswer:string = '/Admin/getUserAnswer'
  answerResponse:string = '/Admin/answerResponse'

  //Revenue
  getRevenue: string = '/Admin/getRevenue'

  //appointments
  // getAppointments: string = '/Admin/getAppointments';


}
