<nav class="navbar navbar-expand navbar-dark">
  <div>
    <!-- <h1>Apptunix</h1> -->
    <figure>
      <img height="45px" width="70px" src="../../../assets/img/svg/appunix_logo.svg">
    </figure>
  </div>
  <a href="#menu-toggle" id="menu-toggle" class="navbar-brand"><span><i class="fa fa-bars"
        aria-hidden="true"></i></span></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02"
    aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarsExample02">
    <div class="header-right">
      <div class="notification">
        <i class="fa fa-bell" aria-hidden="true" routerLink="/notification/notification"></i>
      </div>
      <div class="profile">
        <div class="profile-name prfl_cls">
          <div class="p-img">
            <figure >
              <img src={{img}} />
            </figure>
          </div>
          <div class="p-img" >
            <p>{{ profileName }}</p>
          </div>
          <div class="dropbox">
            <ul>
              <li routerLink="/profile/profile" >
                My Profile
              </li>
              <li routerLink="/profile/changepassword">
                Change Password
              </li>
              <li (click)="logout()">Logout</li>
            </ul>
          </div>
        </div>
        <!-- <div class="profile-name">
          <div class="p-img">
            <figure (click)="showDropBox = !showDropBox">
              <img src={{img}} />
            </figure>
          </div>
          <div class="p-img" (click)="showDropBox = !showDropBox">
            <p>{{ profileName }}</p>
          </div>
          <div class="dropbox" *ngIf="showDropBox">
            <ul>
              <li routerLink="/profile/profile" (click)="showDropBox = false">
                My Profile
              </li>
              <li routerLink="/profile/changepassword" (click)="showDropBox = false">
                Change Password
              </li>
              <li (click)="logout()">Logout</li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</nav>
<div id="wrapper" class="toggled">
  <!-- Sidebar -->
  <div id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <li>
        <a routerLink="/dashboard" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-home" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Dashboard</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/users/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Users</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/manage-content/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-id-card-o" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Application Content</p>
            </div>
          </div></a>
      </li>
      <li>
        <a routerLink="/booking/booking" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-first-order" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Booking</p>
            </div>
          </div>
        </a>
      </li>
      <!-- <li>
        <a routerLink="/support/chat" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-comment" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Chat</p>
            </div>
          </div>
        </a>
      </li> -->
      <li>
        <a routerLink="/tool" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-comment" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Tools</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <!-- <a routerLink="/category/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-server" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Services</p>
            </div>
          </div>
        </a> -->
      </li>
      <li>
        <a routerLink="/careproviders/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-handshake-o" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Care Providers</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/manageclinic/cliniclist" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-hospital-o" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Clinics</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/category/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-newspaper-o" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Categories</p>
            </div>
          </div>
        </a>
      </li>
      <!-- <li>
        <a routerLink="/services/list" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-newspaper-o" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Services</p>
            </div>
          </div>
        </a>
      </li> -->
      <!-- <li>
        <a routerLink="/revenue/revenue" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-line-chart" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Revenue</p>
            </div>
          </div>
        </a>
      </li> -->
      <li>
        <a routerLink="/review/review" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Reviews</p>
            </div>
          </div>
        </a>
      </li>
      <!-- <li>
        <a routerLink="/analytics/analytics" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-bar-chart" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Analytics</p>
            </div>
          </div>
        </a>
      </li> -->
      <li>
        <a routerLink="/notification/notification" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-bell" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Notification</p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/cms/cms" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage CMS</p>
            </div>
          </div>
        </a>
      </li>
      <!-- <li>
        <a routerLink="/settings" routerLinkActive="active">
          <div class="side-main">
            <div class="side-left">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            <div class="sidebar-right">
              <p>Manage Settings</p>
            </div>
          </div>
        </a>
      </li> -->
    </ul>
  </div>
</div>
