import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { HttpService } from '../../services/http/http.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  showDropBox: boolean = false;
  showDropBox1: boolean = false;
  modulesData: any = [];
  profileName: any;
  img: any;

  constructor(
    private router: Router,
    private http: HttpService,
    private common: CommonService
  ) {}
  ngOnInit(): void {
    //this method used to display image and name of Admin
    this.getProfileDetails();
    $(document).on('click', '#menu-toggle', function (event) {
      event.preventDefault();
      $('#wrapper').toggleClass('toggled');
      $('body').toggleClass('body');
    });
    $(window).resize(function (e) {
      if ($(window).width() <= 768) {
        $('#wrapper').removeClass('toggled');
      } else {
        $('#wrapper').addClass('toggled');
      }
    });
    //code is being use when we update the user profile then direct reflect profile name
    this.common.castProfileName.subscribe((res: any) => {
      this.profileName = res?.data?.fullName;
      this.img = res?.data?.image;
      console.log(this.profileName);
    });
  }

  //when load component to get user profile detail.
  getProfileDetails() {
    this.http.httpGetWithHeader('profile').subscribe((res: any) => {
      this.img = res?.data?.image;
      this.profileName = res?.data?.fullName;
    });
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        //this.modulesData = [];
        // this.roleService.modulesData = [];
        localStorage.clear();
        this.router.navigateByUrl('/auth/login');
      }
    });
  }
}
