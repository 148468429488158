import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { catchError, finalize, tap } from 'rxjs/operators'
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.ngxService.start();
      //implementation of tocken and header using interceptor
      const userToken = localStorage.getItem("accessToken");
      let authReq:any;
        authReq = req.clone({
          headers:new HttpHeaders({
            authorization : userToken ? `Bearer ${userToken}` : "",
            "cache-control": "no-cache",
          }),
        });
    return next.handle(authReq)
    .pipe(
      catchError((error: HttpErrorResponse)=>{
        if(error.status == 403){
          this.router.navigateByUrl('/auth/login');
        }
        if (error.status == 401) {
          this.router.navigateByUrl('/auth/login');
          localStorage.clear();
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
      }),
      tap(
          (event) => {
            var eventRes = JSON.parse(JSON.stringify(event));
            if (eventRes.body) {
              if (eventRes.body.statusCode === 400) {
                if (eventRes.body.error) {
                  this.commonService.presentsToast(
                    'error',
                    'top-end',
                    eventRes.body.error
                  );
                } else if (eventRes.body.message) {
                  this.commonService.presentsToast(
                    'error',
                    'top-end',
                    eventRes.body.message
                  );
                }
              }
            }
          },
          (error) => {
            switch (error.status) {
              case 401:
                /***  Auto LogOut if Api response 401 ** */
                this.commonService.presentsToast(
                  'warning',
                  'top-end',
                  'You have been logged out for security purpose.'
                );
                this.router.navigateByUrl('/auth/login');
                localStorage.clear();
                break;
              case 500:
                /*** If api does not respond  ** */
                this.commonService.presentsToast(
                  'warning',
                  'top-end',
                  'Api Not Working'
                );
                break;
              case 404:
                /*** If api does not respond  ** */
                this.commonService.presentsToast(
                  'warning',
                  'top-end',
                  'Api Not Found!'
                );
                break;

              default:
                if (error.error) {
                  error.error.message &&
                    this.commonService.presentsToast(
                      'error',
                      'top-end',
                      error.error.message
                    );
                } else {
                  error.message &&
                    this.commonService.presentsToast(
                      'error',
                      'top-end',
                      error.message
                    );
                }
            }
          }
      )
    )
  }
}
