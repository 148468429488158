import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { apiList } from './api-list';
import {Subject} from 'rxjs';

@Injectable({
  providedIn : 'root'
})
export class HttpService {

  baseUrl: string = environment.baseUrl;
  //Using for passing data


  constructor(
    private http: HttpClient,
    private apiList: apiList
  ) { }

  /**POST API's */
  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }
  httpPostWithHeader(url: string, params: any, isFormData): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      // this.header(isFormData)
    );
  }
  httpPostWithHeaderId(url: string, id, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      // this.header(false)
    );
  }

  //post data using id and form
  httpPostWithHeaderIdForm(url: string,id,isFormData): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url]+ '/' + id,""
      // this.header(isFormData)
    );
  }


  // get user by id
  httpPostWithHeaderIds(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      // this.header(false)
    );
  }

  /**GET API's */
  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }
  // httpGetWithHeader(url: string): Observable<Object> {
  //   return this.http.get(this.baseUrl + this.apiList[url], this.header(false));
  // }

  //dummy check
  httpGetWithHeader(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }


  httpGetWithHeaderParam(url: string, params:any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '?' + params
      // this.header(false)
    );

  }
  httpGetWithHeaderParamAndId(url: string, id, params): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id + '?' + params,
      // this.header(false)
    );
  }
  httpGetHeaderById(url: string, id: string): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id,
      // this.header(false)
    );
  }

  /**PUT API's */
  httpPutWithHeaderBodyAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      // this.header(isFormData)
    );
  }
  httpPutWithHeaderAndId(
    url: string,
    id,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '/' + id, false
      // this.header(isFormData)
    );
  }
  httpPutWithHeaderBody(
    url: string,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      params,
      // this.header(isFormData)
    );
  }

  /**File Upload APi */
  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('file', fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: 'multipart/form-data',
      authorization: localStorage.accessToken,
    });
    const headersObj = { headers };
    return this.http.post(
      this.baseUrl + this.apiList['fileUpload'],
      formData,
      headersObj
    );
  }

  /**Delete Related API */
  httpDeleteWithHeaderAndId(url: string, id, isFormData): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + '/' + id,
      // this.header(isFormData)
    );
  }


  httpDeleteWithHeader(url: string, param, isFormData): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + '?' + param,
      // this.header(isFormData)
    );
  }
}

  /**header */
  // header(isFormData:boolean) {
  //   if (
  //     localStorage.accessToken != undefined ||
  //     localStorage.accessToken != null
  //   ) {
  //     var headers;
  //     if (isFormData) {
  //       headers = new HttpHeaders({
  //         mimeType: 'multipart/form-data',
  //         authorization: localStorage.accessToken,
  //       });
  //     }
  //     else {
  //       headers = new HttpHeaders({
  //         'cache-control': 'no-cache',
  //         'content-type': 'application/json',
  //         // mimeType: "multipart/form-data",
  //         authorization: localStorage.accessToken,
  //       });
  //     }
  //     let option = {
  //       headers,
  //     };
  //    // option['observe'] = 'response';
  //     return option;
  //   }
  // }
