import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: any;
  constructor() {}

  initSocket(data: any = null) {
    if (localStorage.accessToken !== undefined) {
      this.socket = socketIo.connect(environment.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: localStorage.getItem('accessToken'),
        },
      });
    }
      this.socket.on('connect', (res: any) => {
        console.log('debug connect', res);
        // this.emitAction('sendMsg', data);
      });

    this.socket.on('disconnect', (message: any) => {
      console.log('debug disconnect', message);
    });
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }

  close() {
    this.socket.close();
  }
}
